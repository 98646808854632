import React from 'react'
import CreateCustomField from './createCustomField'

const getIDFromPathname = pathname =>
  pathname
    .split('/')
    .pop()

function CustomFieldItem(props) {
  const id = getIDFromPathname(props.location.pathname)
  return <CreateCustomField {...props} id={id} />
}

export default CustomFieldItem;